import { SocialLinks } from 'main/constants';
import {ContactForm} from '../components';
import SunGlasses from 'assets/sunglasses.svg';

const ContactsData = () => (
  <div className="d-flex flex-column flex-grow-1 justify-content-between">
    <div className="address_phone">
      <p className="address">
        11412, Estonia, Tallinn Harju maakond, Majaka tn 26
      </p>
      <a className="phone" href="tel:+372 (610) 42-76">
        +372 (610) 42-76
      </a>
    </div>

    <div className="welcome d-flex flex-column flex-lg-row">
      <a className="email" href="mailto:welcome@miura.one">
        welcome@miura.one
      </a>
      <img src={SunGlasses} alt="" />
    </div>

    <div className="socials">
      {SocialLinks.map((item, i) => (
        <a key={i} href={item.link} target="_blank" className="button" style={{ '--gradient': item.bg } as any}>
          <span>{item.label}</span>
        </a>
      ))}
    </div>
  </div>
);

export function Contacts() {
  return (
    <>
      <div id="contacts" />
      <div className="contacts container mt-40">
        <h1 className="smallTitle">Contacts</h1>

        <div className="d-flex flex-column flex-lg-row">
          <div className="contacts-info col-lg-4">
            <h1>We would love to hear <br /> from you</h1>

            <div className="d-show d-flex flex-column flex-grow-1 justify-content-between mt-2">
              <ContactsData />
            </div>
          </div>

          <div className="contacts-form col-lg-8">
            <ContactForm showContent={true} onClose={() => {}} />
          </div>

          <div className="mb-show mt-4">
            <ContactsData />
          </div>
        </div>
      </div>
    </>
  );
}
