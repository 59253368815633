import React from 'react';
import ReactDOM from 'react-dom';
import { ModalContext } from './ModalContext';
import { MODAL } from './modals';

const Modal = () => {
  const { modal } = React.useContext(ModalContext);

  if (!!modal) {
    return ReactDOM.createPortal(
      <div style={{ position: 'fixed', zIndex: 5 }}>
        {modal === 'project' && MODAL.Project}
        {modal === 'form' && MODAL.Form}
      </div>,
      document.querySelector('#root > div')
    );
  } else return null;
};

export default Modal;
