export function Footer() {
  return (
    <footer>
      <div className="footer">
        <div className="footer__bg">
          <div className="footer__bg--text">
            <p>© 2023 Miura.one</p>
          </div>
          <div className="footer__bg--img" />
        </div>
      </div>
    </footer>
  );
}
