import React from 'react';
import { ModalMeta, ModalType } from './ModalContext';

export default () => {
  let [modal, setModal] = React.useState<ModalType>(null);
  let [modalMeta, setModalMeta] = React.useState(null);

  let handleModal = (modal: ModalType, meta: ModalMeta = null) => {
    setModal(modal);
    setModalMeta(meta);
  };

  return { modal, handleModal, modalMeta };
};
