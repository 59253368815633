import { Drawer } from './Drawer';
import { FormModal } from './FormModal';

export * from './Drawer';
export * from './FormModal';

export const MODAL = {
  Project: <Drawer />,
  Form: <FormModal />
};
