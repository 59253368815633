import BullIcon from 'assets/bullIcon.svg';

export function Header() {
  return (
    <header>
      <div className="header container">
        <div className="header-content">
          <ul>
            <li>
              <a href="#projects">PROJECTS</a>
            </li>
            <li>
              <a href="#about">ABOUT</a>
            </li>
            {/*<li>*/}
            {/*  <a href="#price">PRICE</a>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <a href="#showreel">SHOWREEL</a>*/}
            {/*</li>*/}
            <li>
              <a href="#contacts">CONTACTS</a>
            </li>
          </ul>
        </div>

        <img className="bull-icon" src={BullIcon} alt="Bull icon" />
        <a href="#contacts" className="levitation-button">
          <div>
            <span>GET IN TOUCH</span>
          </div>
        </a>
        <div className="mobileMenu-btn mb-show"></div>
      </div>
    </header>
  );
}
