import React, { useEffect } from 'react';
import useModal from './useModal';
import Modal from './modal';
import { Project } from 'main/constants';
import { ProjectsController } from '../../services';

interface ProjectModalMeta {
  project: Project;
}

export type ModalMeta = ProjectModalMeta;

export type ModalType = 'project' | 'form' | null;

interface ModalContextProps {
  modal: ModalType;
  handleModal: (v: ModalType, meta?: ModalMeta) => void;
  modalMeta: ModalMeta;
}

const ModalContext = React.createContext<ModalContextProps>({
  modal: null,
  handleModal: () => {},
  modalMeta: null
});

const ModalProvider = ({ children }) => {
  const controller = ProjectsController.getInstance();
  let { modal, handleModal, modalMeta } = useModal();

  useEffect(() => {
    setTimeout(() => {
      const code = window.location.pathname.split('/')[1];
      if (code) {
        const project = controller.getProjectByCode(code);
        if (project) {
          handleModal('project', { project });
        }
      }
    }, 500);
  }, []);

  return (
    <ModalContext.Provider value={{ modal, handleModal, modalMeta }}>
      <Modal />
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => React.useContext(ModalContext);

export { ModalContext, ModalProvider };
