import React, { useState } from 'react';
import PlayIcon from 'assets/playIcon.svg';
import YouTube, { YouTubePlayer } from 'react-youtube';

export function Showreel() {
  const [player, setPlayer] = useState<YouTubePlayer>(null);

  const onReady = ({ target }) => {
    setPlayer(target);
  };

  const start = () => {
    player.playVideo();
  };

  return (
    <>
      <div id="price" className="anchor" />
      <div className="showreel container mt-40">
        <h1 className="smallTitle">Showreel</h1>

        <div className="showreel-content d-flex flex-lg-row">
          <YouTube
            className="banner"
            videoId="7p3P6o8xsQ4"
            onReady={onReady}
            opts={{
              playerVars: {
                loop: 1,
                controls: 0,
                showinfo: 0,
                disablekb: 1,
                modestbranding: 1,
                fs: 0,
                rel: 0
              }
            }}
          />

          <div className="play col-lg-4 d-flex flex-lg-column justify-content-between">
            <p>PLAY</p>
            <img src={PlayIcon} alt="play" onClick={start} />
            <p>VIDEO</p>
          </div>
        </div>
      </div>
    </>
  );
}
