import {useEffect, useState} from "react";
import Lottie from 'react-lottie';

interface Props {
  dataPath: string;
}
export function LottieContainer({ dataPath }: Props) {
  const [data, setData] = useState();

  useEffect(() => {
    fetch(dataPath)
      .then((res) => res.json())
      .then(setData);
  }, [dataPath]);

  if (!data) {
    return;
  }

  return (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: data,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      }}
    />
  )
}
