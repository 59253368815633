
export enum Breakpoints {
  over = 1920,
  max = 1440,
  mobile = 768,
  small = 375
}

export interface ProjectTag {
  label: string;
  color: string;
}

export interface Project {
  id: string;
  name: string;
  description: string;
  image: string;
  banner: string;
  lottie: string;
  tags: ProjectTag[];
  realised: string[][];
  detail: string[];
  link: string;
}

export const mapProject = (data: any): Project => {
  if (!data) {
    return;
  }

  return {
    id: data.id,
    name: data.name,
    description: data.description,
    image: data.image ? ('projectsData/' + data.id + '/' + data.image) : '',
    banner: data.banner ? ('projectsData/' + data.id + '/' + data.banner) : '',
    lottie: data.lottie ? ('projectsData/' + data.id + '/' + data.lottie) : '',
    tags: data.tags?.length ? data.tags.map(mapTag) : [],
    realised: data.realised?.length ? data.realised : [],
    detail: data.detail?.length ? data.detail.map(v => v ? ('projectsData/' + data.id + '/' + v) : '').filter(Boolean) : [],
    link: data.link,
  }
}

const mapTag = (object: any): ProjectTag => {
  if (!object) {
    return;
  }

  return {
    label: object.label,
    color: object.color
  }
}

interface SocialLink {
  link: string;
  label: string;
  bg: string;
}

export const SocialLinks: SocialLink[] = [
  { link: 'https://www.behance.net/miura-one', label: 'Behance', bg: 'linear-gradient(90deg, #2255F5 0%, #193B9F 96.8%)' },
  { link: 'https://dribbble.com/miura-one/shots', label: 'Dribbble', bg: 'linear-gradient(90deg, #EB498A 0%, #C51F61 100%)' },
  { link: 'https://dprofile.ru/miura.one/info', label: 'Dprofile', bg: 'linear-gradient(90deg, #EF6D36 0%, #AE2E05 100%)' },
  // { link: '', label: 'Linkedin', bg: 'linear-gradient(90deg, #037CC6 0%, #094A72 99.99%, #053552 100%)' },
  // { link: '', label: 'Facebook', bg: 'linear-gradient(90deg, #19AEFF 0%, #0063E1 100%)' },
  // { link: '', label: 'Instagram', bg: 'linear-gradient(90deg, #8D5BD6 0%, #E22C57 50%, #FDB549 100%)' },
];

export const SocialLinks_Home = SocialLinks.slice(0, 2);
