import { useModalContext } from 'main/components';
import React, {useCallback, useEffect, useState} from 'react';
import BullIcon from 'assets/bullIcon.svg';
import IconLike from 'assets/icon_like.svg';
import { useForm } from 'react-hook-form';
import { Gtag } from 'main/services';
import {Link} from "react-router-dom";

interface FormProps {
  fullName: string;
  company: string;
  email: string;
  phone: string;
  description: string;
  accept: boolean;
}

const defaultForm: FormProps = {
  accept: false,
  company: '',
  description: '',
  email: '',
  fullName: '',
  phone: ''
};

const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const phonePattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

export function ContactForm({ showContent, onClose }) {
  const { handleModal } = useModalContext();
  const [submitted, setSubmitted] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm<FormProps>({ defaultValues: defaultForm });
  const form = watch();

  const onSubmit = useCallback(
    (data: FormProps) => {
      if (submitted) {
        return;
      }

      Gtag.sendForm(`${form.fullName} | ${form.email} | ${form.phone}`);

      setSubmitted(true);
      fetch('/php/send.php', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(() => setIsSuccess(true))
        .finally(() => setSubmitted(false));
    },
    [Gtag, form, submitted, handleSubmit]
  );

  return (
    <div className={'form-content ' + (showContent ? 'isOpen' : '')}>
      <h1>{isSuccess ? 'Your message was successfully sent' : 'LET’S TALK'}</h1>
      {!isSuccess && (
        <form onSubmit={handleSubmit(onSubmit)} className="form flex-column">
          <div className="d-flex flex-column flex-lg-row c-gap-24">
            <div className="form-group">
              <input
                {...register('fullName', { required: true, maxLength: 50 })}
                className={errors.fullName ? 'error' : ''}
                type="text"
                placeholder="Full name*"
              />
              <span className="bar"></span>
              <label>
                {!!errors.fullName && errors.fullName.type === 'required' && 'Required'}
                {!errors.fullName && 'FULL NAME*'}
              </label>
            </div>
            <div className="form-group">
              <input
                {...register('company', { required: true, maxLength: 50 })}
                className={errors.company ? 'error' : ''}
                type="text"
                placeholder="Company*"
              />
              <span className="bar"></span>
              <label>
                {!!errors.company && errors.company.type === 'required' && 'Required'}
                {!errors.fullName && 'COMPANY*'}
              </label>
            </div>
          </div>
          <div className="d-flex flex-column flex-lg-row c-gap-24">
            <div className="form-group">
              <input
                {...register('email', { required: true, pattern: emailPattern })}
                className={errors.email ? 'error' : ''}
                type="email"
                placeholder="E-mail*"
              />
              <span className="bar"></span>
              <label>{!!errors.email || 'E-MAIL*'}</label>
              <label>
                {!!errors.email && errors.email.type === 'required' && 'Required'}
                {!!errors.email && errors.email.type === 'pattern' && 'Invalid format'}
                {!errors.email && 'E-MAIL*'}
              </label>
            </div>
            <div className="form-group">
              <input
                {...register('phone', { required: true, minLength: 8, maxLength: 16, pattern: phonePattern })}
                className={errors.phone ? 'error' : ''}
                type="tel"
                placeholder="Phone*"
              />
              <span className="bar"></span>
              <label>
                {!!errors.phone && errors.phone.type === 'required' && 'Required'}
                {!!errors.phone && errors.phone.type !== 'required' && 'Invalid format'}
                {!errors.phone && 'PHONE*'}
              </label>
            </div>
          </div>
          <div className="d-flex flex-column flex-lg-row">
            <div className="form-group">
              <textarea
                {...register('description', { required: true, maxLength: 250 })}
                className={errors.description ? 'error' : ''}
                rows={4}
                placeholder="Description*"
              />
              <span className="bar"></span>
              <label>
                {!!errors.description && errors.description.type === 'required' && 'Required'}
                {!errors.description && 'DESCRIPTION*'}
              </label>
            </div>
          </div>
          <div className="d-flex flex-column flex-lg-row form-bottom space-between items-center">
            <div className="form-bottom__rules">
              <input
                {...register('accept', { required: true, value: true })}
                className={!!errors.accept ? 'error' : ''}
                id="rule"
                name="accept"
                type="checkbox"
              />
              <label htmlFor="rule" />
              <span>
                I ACCEPT THE TERMS AS LAID OUT IN <a href="/assets/privacy-policy.pdf" target="_blank">PRIVACY&nbsp;POLICY</a>
              </span>
            </div>
            <div
              className={'form-button ' + (submitted ? 'submitted' : '')}
              onClick={() => {
                document.getElementById('submit_btn').click();
              }}
            >
              <span>send</span>
            </div>
            <button id="submit_btn" type="submit" style={{ display: 'none' }} />
          </div>
        </form>
      )}

      {isSuccess && (
        <div className="success">
          <div className="success_content">
            <img src={IconLike} alt="Like" />
            <p>We will contact you</p>
          </div>

          <div className="form-button reverse" onClick={onClose}>
            <span>BACK TO MAIN</span>
          </div>
        </div>
      )}
    </div>
  );
}

export function FormModal() {
  const { handleModal } = useModalContext();
  const [isOpen, setIsOpen] = useState(false);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    setIsOpen(true);
    setTimeout(() => setShowContent(true), 100);
  }, []);

  const close = () => {
    setShowContent(false);
    setTimeout(() => setIsOpen(false), 200);
    setTimeout(() => handleModal(null), 500);
  };

  return (
    <div id="form">
      <div className="form-header container">
        <img className="bull-icon invert" src={BullIcon} alt="Bull icon" />
        <div className="levitation-button pressed" onClick={close}>
          <div>
            <span>CANCEL</span>
          </div>
        </div>
      </div>

      <div className={'form-bg ' + (isOpen ? 'isOpen' : '')} />
      <ContactForm showContent={showContent} onClose={close} />
    </div>
  );
}
