import React, { useCallback, useEffect, useState } from 'react';
import { Breakpoints } from './constants';
import { MainLogo, ModalProvider } from './components';
import { Footer, Header } from './layout';
import { ProjectsList } from './sections/ProjectsList';
import { Contacts } from './sections/Contacts';
import { About } from './sections/About';
import { Showreel } from './sections/showreel';
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider } from 'react-router-dom';

const routes = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={
        <>
          <Header />
          <main>
            <Outlet />
          </main>
          <Footer />
        </>
      }
    >
      <Route
        index
        element={
          <>
            <MainLogo />
            <ProjectsList />
            <About />
            {/*<Price />*/}
            {/*<Reviews />*/}
            <Showreel />
            <Contacts />
          </>
        }
      />
    </Route>
  )
);

function App() {
  const [zoom, setZoom] = useState(1);

  const onResize = useCallback(() => {
    const width = document.body.clientWidth;

    if (width > Breakpoints.over) {
      setZoom(Breakpoints.over / Breakpoints.max);
    } else if (width > Breakpoints.mobile) {
      setZoom(width / Breakpoints.max);
    } else if (width < Breakpoints.mobile) {
      setZoom(width / Breakpoints.small);
    } else {
      setZoom(1);
    }
  }, []);

  useEffect(() => {
    onResize();

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);

  return (
    <ModalProvider>
      <div style={{ zoom, minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
        <RouterProvider router={routes} />
      </div>
    </ModalProvider>
  );
}

export default App;
