enum GtagEvent {
  generateLead = 'generate_lead'
}

export class Gtag {
  private static event(event: GtagEvent, data: any) {
    try {
      (window as any).gtag('event', event, data);
    } catch (_) {
      return;
    }
  }

  static sendForm(value) {
    if (!Gtag) {
      return;
    }
    Gtag.event(GtagEvent.generateLead, {
      currency: 0,
      value
    });
  }
}
