import { SocialLinks } from 'main/constants';
import { Logo } from './Logo';
import HeartIcon from "assets/heartIcon.svg";
import Marquee from "react-fast-marquee";

export function MainLogo() {
  return (
    <div className="homeLogo container">
      <Logo />

      <div className="description d-flex flex-column flex-md-row">
        <div className="col-md-4">
          <h2>Use digital technologies to create mobile apps and complex web systems</h2>
        </div>

        <div className="description-text col-md-4">
          <p>
            We solve various business problems with analytics,
            user experience and interface design.
            We are located in Tallinn 🇪🇪,
            but we cooperate with clients all over the world.
          </p>
        </div>

        <div className="d-flex flex-lg-column col-md-4">
          <div className="d-flex buttons justify-content-lg-end">
            {SocialLinks.map((item, i) => (
              <a
                key={i}
                href={item.link}
                target="_blank"
                rel="noreferrer"
                className="button"
                style={{ '--gradient': item.bg } as any}
              >
                <span>{item.label}</span>
              </a>
            ))}
          </div>
          <div className="d-flex imgs">
            <img src={HeartIcon} alt="Heart icon" />
          </div>
        </div>
      </div>

      <div className="home-marque">
        <Marquee autoFill={true}>
          <div>
            <span>We specialize in</span>
            <span className="box"> UI/UX</span>
            <span>website interfaces and mobile applications.</span>
          </div>
          <div>
            <span>We analyze</span>
            <span className="box"> user needs</span>
            <span>conduct research and testing.</span>
          </div>
          <div>
            <span>We improve</span>
            <span className="box">user experience</span>
          </div>
          <div>
            <span>We have extensive</span>
            <span className="box"> experience and expertise</span>
            <span>of tourism, corporate systems, e-commerce and marketing.</span>
          </div>
        </Marquee>
        <div className="home-marque--border" />
      </div>
    </div>
  );
}
