import CloseIcon from 'assets/closeDrawerIcon.svg';
import { Project } from 'main/constants';
import { useEffect, useState } from 'react';
import { useModalContext } from 'main/components';
import { ProjectsController } from '../../../services';

export function Drawer() {
  const controller = ProjectsController.getInstance();
  const [isOpen, setIsOpen] = useState(false);
  const [currentProject, setCurrentProject] = useState<Project>(null);

  const { modalMeta, handleModal } = useModalContext();

  useEffect(() => {
    if (modalMeta.project) {
      setCurrentProject(modalMeta.project);
      controller.setCurrentProject = modalMeta.project;
    }
  }, [modalMeta]);

  useEffect(() => {
    setTimeout(() => setIsOpen(true), 100);
  }, []);

  const close = () => {
    setIsOpen(false);
    setTimeout(() => handleModal(null), 400);
    controller.setCurrentProject = null;
  };

  const handleClick = e => {
    if (!e.target.closest('.drawer-content')) {
      close();
    }
  };

  const changeProject = (isNext: boolean) => {
    setCurrentProject(controller.getNextProject(isNext));
  };

  return (
    <div id="drawer" className="drawer isOpen" onClick={handleClick}>
      <div className={'drawer-content ' + (isOpen ? 'isOpen' : '')}>
        <img className="drawer-close" src={CloseIcon} alt="close" onClick={close} />

        {currentProject && (
          <div className="drawer-content__main">
            <p className="top-helper">WE CREATED DESIGN</p>
            <h1>{currentProject.name}</h1>
            <div className="project-tags">
              {currentProject.tags.map((tag, i) => (
                <div key={i} className="project-tags--item" style={{ backgroundColor: tag.color }}>
                  {tag.label}
                </div>
              ))}
            </div>
            <div className="description">
              <p>{currentProject.description}</p>
              {currentProject.realised.length && (
                <div className="realised">
                  {currentProject.realised.map((list, i) => (
                    <ul key={i}>
                      {list.map((item, j) => (
                        <li key={j}>{item}</li>
                      ))}
                    </ul>
                  ))}
                </div>
              )}
            </div>

            {Boolean(currentProject.detail.length) && (
              <div className="content">
                {currentProject.detail.map((img, i) => (
                  <img key={i} src={img} alt="" />
                ))}
              </div>
            )}

            <div className="drawer-navigation">
              <div>
                <div
                  onClick={() => changeProject(false)}
                  className="drawer-navigation__button drawer-navigation__button--prev"
                >
                  <div>
                    <svg width="36" height="28" viewBox="0 0 36 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M18 24H16V20H12V16H36V12H12V8H16V4H18V0H12V4H8V8H4V10H2V12H0V16H2V18H4V20H8V24H12V28H18V24Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
                <p>PREVIOS</p>
              </div>
              <div>
                <div
                  onClick={() => changeProject(true)}
                  className="drawer-navigation__button drawer-navigation__button--next"
                >
                  <div>
                    <svg width="36" height="28" viewBox="0 0 36 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M18 24H20V20H24V16H0V12H24V8H20V4H18V0H24V4H28V8H32V10H34V12H36V16H34V18H32V20H28V24H24V28H18V24Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
                <p>NEXT</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
