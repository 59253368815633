import React, { useEffect, useState } from 'react';
import { Project } from 'main/constants';
import { ProjectsController } from '../services';
import { LottieContainer } from '../components';

export function ProjectsList() {
  const controller = ProjectsController.getInstance();
  const [projects, setProjects] = useState<Project[]>([]);

  useEffect(() => {
    if (projects.length) {
      return;
    }
    const interval = setInterval(() => setProjects(controller.projects), 100);
    return () => clearInterval(interval);
  }, [projects]);

  const getLottieData = (path: string) => {
    return fetch(path).then((res) => res.json())
  }

  return (
    <>
      <div id="projects" className="anchor" />
      <div className="container mt-40">
        <h1 className="smallTitle">Projects</h1>

        <div className="projects-list">
          {projects.map((project, i) => (
            <div key={i} className={'projectCard col-4 ' + (!project.link ? 'disabled' : '')}>
              <a href={project.link} target="_blank">
                <div className="projectCard__img">
                  {!project.lottie && (
                    <img src={project.image} alt={project.id} />
                  )}

                  {project.lottie && <LottieContainer dataPath={project.lottie} />}
                </div>
              </a>
              <div className="projectCard__desc d-flex justify-content-between align-items-center">
                <h5 className="projectCard__title">{project.name}</h5>
                <div className="project-tags">
                  {project.tags.map((v, i) => (
                    <span key={i} className="project-tags--item">
                      {v.label}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
