import { useEffect } from 'react';
import eyeBorder from 'assets/eye/eyeBorder.svg';
import eyeCenter from 'assets/eye/eye.svg';
import { Breakpoints } from '../constants';

export function About() {
  const applyEyePositions = (x: number, y: number) => {
    const eyeBalls = document.querySelectorAll('.eye-center div img') as any;

    for (let i = 0; i < eyeBalls.length; i++) {
      eyeBalls[i].style.left = x + '%';
      eyeBalls[i].style.top = y + '%';
      eyeBalls[i].style.transform = 'translate(-' + x + '%,-' + y + '%)';
    }
  };

  const onMouseMove = (event: any) => {
    const eyeContainer = document.querySelector('.eye-center') as any,
      bounds = eyeContainer.getBoundingClientRect(),
      left = bounds.left + bounds.width * 2,
      top = bounds.top + bounds.height * 2;

    const x = (event.clientX / left) * 50,
      y = (event.clientY / top) * 50;

    applyEyePositions(Math.min(x, 120), Math.min(y, 120));
  };

  const onScroll = (event: any) => {
    if (window.innerWidth > Breakpoints.mobile) {
      return;
    }
    const eyeContainer = document.querySelector('.eye-center'),
      height = event.target.clientHeight,
      containerTopOffset = eyeContainer.getBoundingClientRect().top + eyeContainer.getBoundingClientRect().height;

    const y = (containerTopOffset / height) * 100;

    applyEyePositions(90, y);
  };

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);
    document.getElementById('root').addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.getElementById('root').removeEventListener('scroll', onScroll);
    };
  }, [onMouseMove]);

  return (
    <>
      <div id="about" className="anchor" />
      <div className="about container mt-40">
        <div>
          <div>
            <h1 className="smallTitle">WHO WE ARE</h1>

            <div className="d-flex justify-content-between flex-column flex-lg-row">
              <h1 className="about-title col-lg-8">
                We are a full-service digital agency focused on mobile devices that helps companies scale and become
                category leaders
              </h1>

              <div className="eye-container col-lg-4">
                <div className="eye">
                  <img id="eyeBorder" src={eyeBorder} />
                  <div className="eye-center">
                    <div>
                      <img src={eyeCenter} />
                    </div>
                    <div>
                      <img src={eyeCenter} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="about-desc d-flex flex-column flex-lg-row">
            <div className="col-lg-4"></div>
            <div className="col-lg-4 about-description">
              We help startups and global brands. We <br />
              improve your products to achieve key<br />
              performance indicators (KPIs).
              <br />
              <br />
              Our services include creative design,<br />
              website/application development based on<br />
              user experience.
              <br />
              <br />
              Every day, the Miura.one team strives for innovation,<br />
              offering creative solutions that connect<br />
              brands with their target audience.
            </div>
            <div className="col-lg-4 about-description">
              Our goal is to help businesses expand and<br />
              establish themselves as leaders in their<br />
              respective fields.
              <br />
              <br />
              Miura.one is happy to solve the complex<br />
              digital tasks of its customers and firmly<br />
              believes that success at scale is achieved<br />
              through a combination of creative thinking,<br />
              advanced technology and data analysis.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
