import { mapProject, Project } from '../constants';

export class ProjectsController {
  private static instance: ProjectsController;

  public projects: Project[];
  private currentProject: Project;

  set setCurrentProject(project: Project) {
    this.currentProject = project;

    if (project) {
      OpenDrawer(project);
    } else {
      CloseDrawer();
    }
  }

  public static getInstance(): ProjectsController {
    if (!ProjectsController.instance) {
      ProjectsController.instance = new ProjectsController();
      ProjectsController.instance.projects = [];
      ProjectsController.instance.currentProject = null;

      fetch(`projectsData/data.json`)
        .then(res => res.json())
        .then(data => (data.projects?.length ? data.projects.map(mapProject) : []))
        .then(data => (ProjectsController.instance.projects = data));
    }

    return ProjectsController.instance;
  }

  public getProjectByCode(code: string): Project {
    if (!code) {
      return null;
    }
    return this.projects.find(v => v.id === code);
  }

  public getNextProject(isNext = false): Project {
    const delta = isNext ? 1 : -1;
    const nextIndex = (this.projects.findIndex(v => v.id === this.currentProject.id) + delta) % this.projects.length;
    const nextProject =
      nextIndex < 0 ? this.projects[this.projects.length - 1] : this.projects?.[nextIndex] || this.projects[0];

    this.currentProject = nextProject;
    this.setCurrentProject = nextProject;
    return nextProject;
  }
}

function OpenDrawer(project: Project) {
  window.history.replaceState(null, project.name, '/' + project.id);
}

function CloseDrawer() {
  window.history.replaceState(null, null, '/');
}
